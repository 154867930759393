div.Note {
	background-color: #182438;
	color: #cbd9f0;
	text-align: left;
	overflow: hidden;
	padding: 0;
	margin: 0;
	width: 100%;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
}

div.NoteInner {
	background-color: #182438;
	text-align: left;
	color: #cbd9f0;
	overflow: hidden;
	height: 100%;
	width: 100%;
}

input.Title {
	background-color: #1c3462;
	color: #cbd9f0;

	margin-left: 4px;
	margin-bottom: 4px;

	font-size: 20px;
	font-family:"Lucida Sans Unicode";

	width: calc(100% - 14px);
}

textarea.Body {
	/*background-color: #1c3462;*/
	background-color: #182438;
	color: #cbd9f0;
	border-color: #346DD8;

	font-size: 16px;
	font-family:"Lucida Sans Unicode";
	overflow: auto;
	resize: none;

	outline: none;
	border-style: solid;

	border-width: 0px 0px 0px 1px;
	padding: 16px 16px 16px 16px;

	width: calc(100% - 34px);
	/*height: calc(100% - 50px);*/
	height: calc(100% - 32px);
}