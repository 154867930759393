.flex-container {
	background-color: #182438;
	padding: 0;
	margin: 0;
	overflow: auto;
	overflow-y: auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;

	height: 100%;
	width: 256px;
}

.flex-item {
	width: calc(100% - 16px);
	height: 32px;
	min-height: 32px;
	padding: 8px;
	margin: 0px;

	text-align: left;
	text-decoration: none;
	text-overflow: ellipsis;
	font-size: 20px;
	font-family:"Lucida Sans Unicode";
	color: #cbd9f0;
	cursor: pointer;
	border-bottom: 1px solid #346DD8;
	background-color: #182438;
	overflow: hidden;
	white-space: nowrap;
	word-wrap: normal;
}

.flex-item:hover {
	color: #cbd9f0;
	background-color: #264582;
}

.flex-selected {
	color: #cbd9f0;
	background-color: #335EB2;
}