html {
  height: 100%;
}

body {
	height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
	min-height: 100%;
	height:100%;
	width:100%;
}