.firebaseui-container {
	background: rgba(0, 0, 0, 0.05);
	margin-bottom: 15px;
	min-height: 150px;
	padding-top: 10px;
	border-radius: 10px;
	box-shadow: none;
}

.firebaseui-container.firebaseui-page-provider-sign-in {
	background: #aaa;
	box-shadow: none;
	min-height: 0;
	margin-bottom: 0;
	padding-top: 0;
	text-align: center;
}