.App {
	text-align: center;
	height: 100%;
	width: 100%;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

.App-header {
	background-color: #2B4166;
	height: 150px;
	padding: 20px;
	color: white;
}

.App-title {
	font-size: 1.5em;
}

.App-intro {
	font-size: large;
}

@keyframes App-logo-spin {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}

ul.NavBar {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	color: #cbd9f0;
	background-color: #2B4166;
}

li {
	float: left;
	cursor: pointer;
	font-family:"Lucida Sans Unicode";
}

li a {
	display: block;
	color: white;
	text-align: center;
	padding: 16px 32px;
	text-decoration: none;
}

li a:hover {
	background-color: #182438;
}

.SignOut{
	float: right;
}

.AppName {
	font-family:"Lucida Sans Unicode";
	color: white;
	cursor: default;
	font-size: 24px;
	padding: 10px 20px 10px 20px;
}

.SignInPrompt {
	font-family:"Lucida Sans Unicode";
	font-size: 30px;
	padding: 20px 20px 20px 20px;
}

.Loading {
	text-align: center;
	font-family:"Lucida Sans Unicode";
	font-size: 30px;
	padding: 100px;
}

.Welcome {
	font-family: "Lucida Sans Unicode";
	font-size: 30px;
	margin-left: 400px;
}

.SignedIn {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	height: calc(100% - 57px);
	width: auto;
}
